import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { mainStore, userToolStore, companyConfiguracaoIntegracaoBancariaStore, } from '@/utils/store-accessor';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.search = '';
        this.headers = [
            {
                text: 'Company',
                value: 'company.nome_fantasia',
            },
            {
                text: 'Banco',
                value: 'item.banco.nome',
            },
            {
                text: 'Chave pix',
                value: 'item.chave_pix',
            },
            {
                text: 'Vencimento',
                value: 'vencimento',
            },
        ];
        this.loading = false;
        this.items = [];
    }
    get allowView() {
        if (userToolStore.userToolsIndexed.Certificados) {
            return userToolStore.userToolsIndexed.Certificados[0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Certificados) {
            return userToolStore.userToolsIndexed.Certificados[0]
                .allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Certificados) {
            return userToolStore.userToolsIndexed.Certificados[0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Certificados) {
            return userToolStore.userToolsIndexed.Certificados[0]
                .allow_delete;
        }
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    getAvisoVencimento(item) {
        const vencimento = new Date(item.vencimento);
        const data_atual = new Date();
        const difference_In_Time = vencimento.getTime() - data_atual.getTime();
        const difference_In_Days = Math.round(difference_In_Time / (1000 * 3600 * 24));
        return difference_In_Days < 30;
    }
    async atualizaTodos() {
        await companyConfiguracaoIntegracaoBancariaStore.updateCompanyConfiguracaoIntegracaoBancariaCertificados();
    }
    async syncCertificados() {
        this.loading = true;
        await companyConfiguracaoIntegracaoBancariaStore.syncCompanyConfiguracaoIntegracaoBancariaCertificados();
        this.items =
            await companyConfiguracaoIntegracaoBancariaStore.getCompanyConfiguracaoIntegracaoBancariaAtivaAdm();
        this.loading = false;
    }
    async mounted() {
        this.loading = true;
        this.items =
            await companyConfiguracaoIntegracaoBancariaStore.getCompanyConfiguracaoIntegracaoBancariaAtivaAdm();
        this.loading = false;
    }
};
List = __decorate([
    Component({
        components: {
            OpenNewTabComponent,
        },
    })
], List);
export default List;
